import Modal from "../../../layouts/Modal";
import { ICustomerPriceRule, CustomerPriceRuleType } from "../../../models/PriceSettings";

import PriceRuleSingle from "./PriceRuleSingle";

interface PriceRuleModalProps {
    open: boolean,
    setOpen: React.Dispatch<React.SetStateAction<boolean>>,
    customerPriceConfigurationId: string,
    ruleName: string,
    ruleType: CustomerPriceRuleType,
    priceRule?: ICustomerPriceRule;
}

export default function PriceRuleModal({ open, setOpen, customerPriceConfigurationId, ruleName, ruleType, priceRule }: PriceRuleModalProps) {

    return (
        <Modal width="max-w-6xl" open={open} setOpen={setOpen} >
            <h1 className="text-xl">{`${priceRule ? "Edit" : "Add"} Price Rule`}</h1>
            
            <div className="mt-4">
                <div className="space-y-4 border-t border-gray-200" >
                    <div className="pt-4 grid grid-cols-1 gap-x-4 gap-y-4 sm:gap-y-6 sm:grid-cols-1">
                        {priceRule
                        ?
                            <PriceRuleSingle 
                                customerPriceConfigurationId={customerPriceConfigurationId} 
                                priceRule={priceRule} 
                                ruleName={ruleName}
                                ruleType={ruleType}
                                onSuccess={() => setOpen(false)}
                            />
                        :
                            <PriceRuleSingle 
                                customerPriceConfigurationId={customerPriceConfigurationId} 
                                ruleName={ruleName}
                                ruleType={ruleType}
                                onSuccess={() => setOpen(false)}
                            />
                        }
                        
                    </div>
                </div>
            </div>
        </Modal>
    )
}