import { Fragment, HTMLProps, useEffect, useRef, useState } from "react";

import date from "date-and-time";
import { useNavigate } from "react-router-dom";

import { CameraIcon, ChevronRightIcon } from "@heroicons/react/24/outline";
import { StarIcon } from "@heroicons/react/24/solid";
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getExpandedRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  SortingState,
  useReactTable,
} from "@tanstack/react-table";

import useLeads from "../../../data/useLeads";
import useMessages from "../../../data/useMessages";
import useDefaultCRUDHandlers from "../../../hooks/useDefaultCRUDHandlers";
import Modal from "../../../layouts/Modal";
import { ILead, LeadQualified, LeadStatus } from "../../../models/Lead";
import {
  CollectionReminder,
  QualifyLead,
} from "../../../services/TemplateService";
import classNames from "../../../utils/classNames";
import useNotification from "../../notifications/useNotifications";
import Pagination from "../../shared/Tables/Pagination";
import LeadsTableCallCount from "./TableSubComponents/LeadsTableCallCount";
import LeadsTableSubComponent from "./TableSubComponents/LeadsTableSubComponent";
import LeadUnreadMessage from "./TableSubComponents/LeadUnreadMessage";
import { ButtonInput } from "../../shared/Inputs/ButtonInput";
import BulkCompleteLeads from "./BulkCompleteLeads";
import openInNewTab from "../../../utils/openInNewTab";
import PriceFormat from "../../../utils/priceFormat";
import { Button } from "../../base/Button";

function IndeterminateCheckbox({
  indeterminate,
  className = "",
  ...rest
}: { indeterminate?: boolean } & HTMLProps<HTMLInputElement>) {
  const ref = useRef<HTMLInputElement>(null!);

  useEffect(() => {
    if (typeof indeterminate === "boolean") {
      ref.current.indeterminate = !rest.checked && indeterminate;
    }
  }, [ref, indeterminate]);

  return (
    <input
      type="checkbox"
      ref={ref}
      className={className + "p-3 cursor-pointer"}
      {...rest}
    />
  );
}

const colHelper = createColumnHelper<ILead>();

const cols = [
  // {
  //   id: "select",
  //   header: ({ table }: any) => (
  //     <IndeterminateCheckbox
  //       {...{
  //         checked: table.getIsAllRowsSelected(),
  //         indeterminate: table.getIsSomeRowsSelected(),
  //         onChange: table.getToggleAllRowsSelectedHandler(),
  //       }}
  //     />
  //   ),
  //   cell: ({ row }: any) => (
  //     <div
  //       className="flex justify-center items-center flex-1 p-4"
  //       onClick={(e) => e.stopPropagation()}
  //     >
  //       <IndeterminateCheckbox
  //         {...{
  //           id: row.original.id,
  //           checked: row.getIsSelected(),
  //           disabled: !row.getCanSelect(),
  //           indeterminate: row.getIsSomeSelected(),
  //           onChange: row.getToggleSelectedHandler(),
  //         }}
  //       />
  //     </div>
  //   ),
  // },
  colHelper.display({
    id: "image",
    cell: (info) => (
      <span className="flex justify-center relative">
        {info.row.original.vehicle?.vehicleImageUrl ? (
          <img
            className="w-32 h-20 object-contain"
            src={`${
              info.row.original.vehicle != null
                ? info.row.original.vehicle.vehicleImageUrl
                : null
            }`}
          />
        ) : (
          <CameraIcon className="w-14 text-gray-600" />
        )}
        {info.row.original.vehicle?.vehiclePremium && (
          <StarIcon className="w-8 h-8 text-amber-500 absolute -top-2 -right-2" />
        )}
      </span>
    ),
  }),
  colHelper.accessor("contact.fullname", {
    header: "Name",
    sortingFn: "auto",
  }),
  colHelper.accessor("contact.telephone1", {
    header: "Telephone",
    enableSorting: false,
  }),
  colHelper.accessor("vehicle.postcode", {
    header: "Postcode",
    enableSorting: false,
  }),
  colHelper.accessor("vehicle.vrm", {
    header: "VRM",
    enableSorting: false,
  }),
  colHelper.accessor("status", {
    header: "Status",
    enableSorting: false,
    cell: (info) => <span>{LeadStatus[info.getValue()]}</span>,
  }),
  // colHelper.accessor("callCount", {
  //   header: "Call List",
  //   enableSorting: false,
  //   cell: (info) => <LeadsTableCallCount lead={info.row.original} />,
  // }),
  colHelper.accessor("vehicle.scheduledCollectionOn", {
    header: "Collection",
    sortingFn: "auto",
    cell: (collection) => (
      <span>
        {collection.getValue() !== null
          ? date.format(new Date(collection.getValue()!), "DD/MM/YYYY")
          : "Not Set"}
      </span>
    ),
  }),
  // colHelper.accessor("id", {
  //   header: "Unread Messages",
  //   sortingFn: "auto",
  //   cell: (info) => <LeadUnreadMessage lead={info.row.original} />,
  // }),
];

export default function LeadsTable({ data }: { data: ILead[] }) {
  const navigate = useNavigate();
  const { bulkUpdate, bulkComplete, update } = useLeads(true);
  const { addNotification } = useNotification();
  const { saveHandlers } = useDefaultCRUDHandlers("Leads");
  const [updateOpen, setUpdateOpen] = useState(false);
  const [completeOpen, setCompleteOpen] = useState(false);
  const [sorting, setSorting] = useState<SortingState>([]);
  const [selectedStatus, setSelectedStatus] = useState("0");

  const handleRowUpdate = () => {
    var leadIds = new Array();

    table.getSelectedRowModel().flatRows.forEach((lead) => {
      leadIds.push(lead.original.id);
    });

    const body = {
      status: parseInt(selectedStatus as keyof LeadStatus),
      leads: leadIds,
    };

    bulkUpdate.mutate(body, {
      onSuccess: () => {
        table.resetRowSelection();
        saveHandlers.onSuccess();
        setUpdateOpen(false);
      },
      onError: saveHandlers.onError,
    });
  };

  useEffect(() => {
    table.setPageSize(50);
  }, []);

  const table = useReactTable({
    data: data ?? [],
    columns: cols,
    state: {
      sorting: sorting,
    },
    enableRowSelection: true,
    getExpandedRowModel: getExpandedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
  });

  const handleClassNames = (lead: ILead) => {
    let classNames = "";

    if (!lead.vehicle?.found) classNames = "bg-gray-200 ";
    // else if (
    //   lead.qualified === LeadQualified.Qualified &&
    //   lead.status === LeadStatus["Awaiting Contact"]
    // )
    //   classNames = "bg-green-200 ";
    // else if (
    //   lead.qualified === LeadQualified.Stop &&
    //   lead.status === LeadStatus["Awaiting Contact"]
    // )
    //   classNames = "bg-red-200 ";
    // else if (
    //   lead.qualified === LeadQualified["No Match"] &&
    //   lead.status === LeadStatus["Awaiting Contact"]
    // )
    //   classNames = "bg-yellow-200 ";
    // else 
    if (
      lead.website === 6
    )
      classNames = "bg-green-200/80"

    // if (lead.vehicle?.vehiclePremium) {
    //   classNames += "border-4 border-amber-500 "
    // }

    return classNames;
  };

  return (
    <>
      {/* <Modal width="max-w-2xl" open={updateOpen} setOpen={setUpdateOpen}>
        <h1 className="text-xl">Bulk Update</h1>
        <div className="mt-4">
          <p>Change all selected leads to which status?</p>
          <div>
            <select
              onChange={(e) => setSelectedStatus(e.target.value)}
              className="mt-2 border border-gray-400 rounded-md text-sm"
            >
              {Object.values(LeadStatus)
                .filter((ct) => isNaN(Number(ct)))
                .map((status, i) => (
                  <option key={status} value={i}>
                    {status}
                  </option>
                ))}
            </select>
          </div>
          <button
            className="mt-5 inline-flex items-center rounded-md border border-transparent bg-gp-blue-600 px-3 py-2 text-sm font-medium leading-4 text-white shadow-sm hover:bg-gp-blue-700"
            onClick={handleRowUpdate}
          >
            Update
          </button>
        </div>
      </Modal>
      <BulkCompleteLeads
        leads={data}
        table={table}
        open={completeOpen}
        setOpen={setCompleteOpen}
      /> */}
{/* 
      <div className="mt-4">
        <Button
          disabled={table.getSelectedRowModel().flatRows.length === 0}
          onClick={() => setCompleteOpen(true)}
        >
          Bulk Complete
        </Button>
      </div> */}
      <div className="mt-4 bg-white overflow-auto">
        <table className="min-w-full overflow-scroll divide-y border divide-gray-300">
          <thead>
            {table.getHeaderGroups()?.map((headerGroup) => (
              <tr
                key={headerGroup.id}
                className="bg-secondary-50 text-gray-800"
              >
                {headerGroup.headers.map((header, i) => (
                  <th
                    key={header.id}
                    scope="col"
                    className="py-3.5 px-3 text-sm font-semibold lg:table-cell text-left"
                  >
                    {header.isPlaceholder ? null : (
                      <div
                        {...{
                          className: header.column.getCanSort()
                            ? "cursor-pointer select-none"
                            : "",
                          onClick: header.column.getToggleSortingHandler(),
                        }}
                      >
                        {flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}
                        {{
                          asc: " ▲",
                          desc: " ▼",
                        }[header.column.getIsSorted() as string] ?? null}
                      </div>
                    )}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody>
            {table.getRowModel().rows.map((row, i) => {
              let rowCells = row.getVisibleCells();
              return (
                <Fragment key={i}>
                  <tr
                    key={row.id}
                    onClick={() => openInNewTab(`leads/${row.original.id}`)}
                    className={classNames(
                      handleClassNames(row.original),
                      "hover:bg-gray-100 hover:cursor-pointer"
                    )}
                  >
                    {rowCells.map((cell) => (
                      <td key={cell.id} className="px-3 py-3 border text-sm">
                        {flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext()
                        )}
                      </td>
                    ))}
                  </tr>
                  {row.getIsExpanded() && (
                    <tr>
                      {/* 2nd row is a custom 1 cell row */}
                      <td colSpan={row.getVisibleCells().length}>
                        <LeadsTableSubComponent lead={row.original} />
                      </td>
                    </tr>
                  )}
                </Fragment>
              );
            })}
          </tbody>
        </table>
        <Pagination table={table} />
      </div>
    </>
  );
}
