import classNames from "../../../utils/classNames";


interface IOptions {
  value: Boolean;
  name: string;
}

interface IFormRadioGroupIconsProps {
  label: string;
  register: any;
  registerName: string;
  watch: any;
  setValue: any;
  options: { name: string; value: Boolean }[];
  containerClassName?: string;
}

export default function FormRadioGroupIcons({ label, register, registerName, watch, setValue, options, containerClassName = "" }: IFormRadioGroupIconsProps) {

  const selectedValue = watch(registerName);

  return (
    <div className={classNames("flex flex-col bg-white space-y-1", containerClassName)}>
      {label && (
        <label className="select-none text-center pt-2 text-gp-blue-600 font-bold opacity-60">
          {label}
        </label>
      )}
      <div className="flex flex-col lg:flex-row py-2">
        {options.map((option) => {
          const value = option.value;
          const isSelected = selectedValue?.toString() === value?.toString();

          // console.log("isSelected", option.name, value, selectedValue, isSelected);

          return (
            <label
              key={option.name}
              onClick={() => setValue(registerName, value)}
              className={classNames(
                "first:rounded-l last:rounded-r cursor-pointer py-[6.5px] px-4 border text-center transition-all duration-200 select-none",
                isSelected
                  ? "bg-primary-600 text-white border-primary-600"
                  : "bg-white text-black border-gray-300 hover:bg-gray-100",
              )}
            >
              <input
                type="radio"
                className="hidden"
                {...register(registerName)}
                value={value}
                checked={isSelected}
              />
              <span className="font-bold">{option.name}</span>
            </label>
          );
        })}
      </div>
    </div>
  );
};

const options = [
  { name: "Option One", value: true },
  { name: "Option Two", value: false },
];
