import { useEffect, useState } from "react";

import { useForm } from "react-hook-form";

import usePriceSettings from "../../data/usePriceSettings";
import useDefaultCRUDHandlers from "../../hooks/useDefaultCRUDHandlers";
import {
  ICustomerGenericPriceSetting,
  ICustomerPriceConfiguration,
} from "../../models/PriceSettings";
import { ButtonInput } from "../shared/Inputs/ButtonInput";
import SwitchInput from "../shared/Inputs/SwitchInput";
import GeneralPriceSettingSingle from "./PriceConfiguration/GeneralPriceSettingSingle";
import LoadingWheel from "../shared/LoadingWheel";
import SpecificPriceSingle from "./SpecificConfiguration/SpecificPriceSingle";
import SpecificPriceTable from "./SpecificConfiguration/SpecificPriceTable";
import SpecificPriceModal from "./SpecificConfiguration/SpecificPriceModal";
import { PlusSmallIcon } from "@heroicons/react/20/solid";


export default function SpecificPriceSettings() {
  const { priceSettings } = usePriceSettings();
  const { saveHandlers } = useDefaultCRUDHandlers("Price Settings");
  const [isLoading, setIsLoading] = useState(true);
  const [modalOpen, setModalOpen] = useState(false);
  const [editSetting, setEditSetting] = useState<ICustomerGenericPriceSetting>();
  const [specificPriceSettings, setSpecificPriceSettings] = useState<
    ICustomerGenericPriceSetting[]
  >([]);

  useEffect(() => {
    if (!priceSettings.isLoading) {
      if (priceSettings.data?.data.genericPriceSettings && priceSettings.data?.data.genericPriceSettings.length > 0) {
        setSpecificPriceSettings(priceSettings.data?.data.genericPriceSettings);
      }
      setIsLoading(false);
    }
  }, [priceSettings.data, priceSettings.isLoading]);

  const handleSetSpecificPriceSetting = (specificPriceSetting: ICustomerGenericPriceSetting) => {
    setEditSetting(specificPriceSetting);
    setModalOpen(true);
  }

  const handleAddSpecificPriceSetting = () => {
    setEditSetting(undefined);
    setModalOpen(true);
  }

  if (isLoading) return <LoadingWheel />;

  return (
    <>
      <SpecificPriceModal 
        open={modalOpen} 
        setOpen={setModalOpen} 
        customerPriceConfigurationId={priceSettings.data?.data.customerPriceConfiguration?.id!} 
        specificPriceSetting={editSetting}
      />
      {/* <div className="bg-white shadow rounded p-4">

        <h1 className="text-xl font-semibold">Create New Setting</h1>
        <hr />

        <SpecificPriceSingle customerPriceConfigurationId={priceSettings.data?.data.customerPriceConfiguration?.id!} />
      </div> */}

      <div className="mt-4 flex justify-between">

        <button
          className=" bg-primary-600 hover:bg-primary-700 inline-flex items-center rounded-md border border-transparent px-3 py-2 text-sm font-medium leading-4 text-white shadow-sm"
          type="button"
          onClick={() => handleAddSpecificPriceSetting()}
        >
          <PlusSmallIcon className="h-5 w-5 mr-2" />
          Add New Setting
        </button>

      </div>

      <div className="bg-white shadow rounded p-4 mt-4">

        <h1 className="text-xl font-semibold">All Settings</h1>
        <hr />

        <div className="flex flex-col divide-y gap-y-6">

          {/* {specificPriceSettings.map((priceSetting) => (
            <SpecificPriceSingle key={priceSetting.id} customerPriceConfigurationId={priceSettings.data?.data.customerPriceConfiguration?.id!} specificPriceSetting={priceSetting} />
          ))} */}

          <SpecificPriceTable 
            specificPriceSettings={specificPriceSettings} 
            setSpecificPriceSetting={handleSetSpecificPriceSetting} 
          />
        
        </div>
      </div>
    </>

  )
}
