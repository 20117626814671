import { useForm } from "react-hook-form";
import {
  ICustomerPriceRule,
  PriceCondition,
  CustomerPriceRuleType,
} from "../../../models/PriceSettings";
import { TextInput } from "../../shared/Inputs/TextInput";
import SelectInput from "../../shared/Inputs/SelectInput";
import GeneralPriceSettingSingle from "../PriceConfiguration/GeneralPriceSettingSingle";
import { ButtonInput } from "../../shared/Inputs/ButtonInput";
import useDefaultCRUDHandlers from "../../../hooks/useDefaultCRUDHandlers";
import usePriceSettingsMutations from "../../../data/usePriceSettingsMutations";
import { useEffect, useState } from "react";

interface IPriceRuleParams {
  customerPriceConfigurationId: string;
  priceRule?: ICustomerPriceRule;
  ruleName: string;
  ruleType: CustomerPriceRuleType;
  onSuccess?: any;
}

export default function PriceRuleSingle({
  customerPriceConfigurationId,
  priceRule,
  ruleName,
  ruleType,
  onSuccess,
}: IPriceRuleParams) {
  const { createPriceRule, updatePriceRule, removePriceRule } = usePriceSettingsMutations();
  const { saveHandlers, deleteHandlers } = useDefaultCRUDHandlers(`${ruleName} Rule`);
  const [isLoading, setIsLoading] = useState(true);
  const { handleSubmit, register, setValue, watch, reset } = useForm<ICustomerPriceRule>({
    defaultValues: {
      id: priceRule?.id || "",
      customerPriceConfigurationId: customerPriceConfigurationId,
      value: priceRule?.value || 0,
      type: ruleType,
      condition: priceRule?.condition || PriceCondition.LessThan,

      priceSetting: {
        value: priceRule?.priceSetting?.value || 0,
        isFixed: priceRule?.priceSetting?.isFixed || true,
      },
    },
  });

  const enumArray = [
    ...Object.values(PriceCondition).filter((ct) => isNaN(Number(ct))),
  ];

  const options = enumArray.map((opt, i) => {
    return {
      id: i,
      name: opt
        .toString()
        .replace(/([A-Z])/g, " $1")
        .trim(),
    };
  });

  const onSubmit = (data: any) => {
    if (priceRule) {
      updatePriceRule.mutate(
        {
          ...data,
          priceSetting: {
            ...data.priceSetting,
            value:
              data.priceSetting.value !== "" && data.priceSetting.value !== null
                ? parseFloat(data.priceSetting.value)
                : 0,
            isFixed: data.priceSetting.isFixed === "true" ? true : false,
          },
        },
        {
          ...saveHandlers,
          onSuccess: () => {
            saveHandlers.onSuccess();
            onSuccess();
          },
          onError: (error) => {
            saveHandlers.onError();
            console.error(error);
          },
        }
      );
    } else {
      data.id = null;
      createPriceRule.mutate(
        {
          ...data,
          priceSetting: {
            ...data.priceSetting,
            value:
              data.priceSetting.value !== "" && data.priceSetting.value !== null
                ? parseFloat(data.priceSetting.value)
                : 0,
            isFixed: data.priceSetting.isFixed === "true" ? true : false,
          },
          value: data.value !== "" && data.value !== null ? parseFloat(data.value) : "",
        },
        {
          ...saveHandlers,
          onSuccess: () => {
            saveHandlers.onSuccess();
            onSuccess();
            reset();
          },
          onError: (error) => {
            saveHandlers.onError();
            console.error(error);
          },
        }
      );
    }
  };

  const onRemove = () => {
    if (priceRule) {
      removePriceRule.mutate(priceRule.id, {
        ...deleteHandlers,
        onSuccess: () => {
          deleteHandlers.onSuccess();
          onSuccess();
        },
      });
    }
  };

  useEffect(() => {
    if (priceRule && isLoading) {
      reset(priceRule);
      setIsLoading(false);
    } else if (!priceRule && isLoading) {
      setIsLoading(false);
    }
  }, [priceRule]);

  if (isLoading) return null;


  return (
    <form onSubmit={handleSubmit(onSubmit)} className="space-y-4 pt-4">
      <div className="flex flex-col lg:flex-row items-center gap-x-4">
        <SelectInput
          label="Condition"
          register={register}
          registerName={`condition`}
          options={options}
          setValue={setValue}
          watch={watch}
          cols="w-64"
        />
        <TextInput
          label={"Value"}
          registerName={`value`}
          register={register}
          placeholder="Make, Model, Year etc. "
          cols="w-64"
        />

        {/* divider */}
        <div className="border-t-2 border-dashed border-primary-600 w-10 mt-7"></div>

        <GeneralPriceSettingSingle
          label={"Price Change"}
          objectName={"priceSetting"}
          register={register}
          setValue={setValue}
          watch={watch}
          minVal={null}
        />

        <div className="flex grow mt-[28px] space-x-4">
          <ButtonInput label={priceRule ? "Save" : "Create"} isSubmit />
          {priceRule && (
            <ButtonInput
              label="Delete"
              isSubmit={false}
              onClick={onRemove}
              classes="bg-red-600 hover:bg-red-600/80"
            />
          )}
        </div>
      </div>
    </form>
  );
}
