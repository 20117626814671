import { Fragment, useState } from "react";
import {
  flexRender,
  getCoreRowModel,
  getExpandedRowModel,
  getSortedRowModel,
  useReactTable,
  createColumnHelper,
  SortingState,
} from "@tanstack/react-table";
import { ICustomerGenericPriceSetting } from "../../../models/PriceSettings";

const colHelper = createColumnHelper<ICustomerGenericPriceSetting>();

const cols = [
    // colHelper.display({
    //     id: "name",
    //     header: "Name",
    //     sortingFn: "auto",
    //     cell: (info) => (
    //       <span className="flex  " >
    //         <span className="ml-4 m-auto">{info.row.original.name}</span>
    //       </span>
    //     )
    // }),
    colHelper.accessor("make", {
        header: "Make",
    }),
    colHelper.accessor("model", {
        header: "Model",
    }),
    colHelper.accessor("fuelType", {
        header: "Fuel Type",
    }),
    colHelper.accessor("engineCode", {
        header: "Engine Code",
    }),
    colHelper.accessor("fromYear", {
        header: "From Year",
    }),
    colHelper.accessor("toYear", {
        header: "To Year",
    }),  
    colHelper.accessor("fromEngineCc", {
        header: "From CC",
    }),  
    colHelper.accessor("toEngineCc", {
        header: "To CC",
    }),
    colHelper.display({
        id: "price",
        header: "Price",
        sortingFn: "auto",
        cell: (info) => (
          <span className="flex" >
            <span className="ml-4 m-auto">
                {info.row.original.priceSetting?.isFixed
                ? `£${info.row.original.priceSetting?.value}`
                : `${info.row.original.priceSetting?.value}%`
                }
            </span>
          </span>
        )
    }),
];


export default function SpecificPriceTable({ specificPriceSettings, setSpecificPriceSetting }: { specificPriceSettings: ICustomerGenericPriceSetting[], setSpecificPriceSetting: any }) {
    const [sorting, setSorting] = useState<SortingState>([]);

    const table = useReactTable({
        data: specificPriceSettings,
        columns: cols,
        state: {
        sorting: sorting,
        },
        enableRowSelection: true,
        getExpandedRowModel: getExpandedRowModel(),
        onSortingChange: setSorting,
        getCoreRowModel: getCoreRowModel(),
        getSortedRowModel: getSortedRowModel(),
    });


    return (
        <>
            <div className="mt-4 bg-white overflow-auto">
                <table className="min-w-full overflow-scroll divide-y border divide-gray-300">
        
                {/* Table headings - very similar to all the other tables */}
                <thead>
                    {table.getHeaderGroups().map((headerGroup) => (
                    <tr key={headerGroup.id} className="bg-gp-blue-50 text-gray-800">
                        {headerGroup.headers.map((header, i) => (
                        <th
                            key={header.id}
                            scope="col"
                            className="py-3.5 px-3 text-sm font-semibold lg:table-cell text-left"
                        >
                            {header.isPlaceholder
                            ? null
                            : (
                                <div
                                {...{
                                    className: header.column.getCanSort()
                                    ? 'cursor-pointer select-none'
                                    : '',
                                    onClick: header.column.getToggleSortingHandler(),
                                }}
                                >
                                {flexRender(
                                    header.column.columnDef.header,
                                    header.getContext()
                                )}
                                {{
                                    asc: ' ▲',
                                    desc: ' ▼',
                                }[header.column.getIsSorted() as string] ?? null}
                                </div>
        
                            )}
                        </th>
                        ))}
                    </tr>
                    ))}
                </thead>
                
                {/* Body */}
                <tbody>
                {table.getRowModel().rows.map((row, i) => {
                    let rowCells = row.getVisibleCells();
                    return (
                        <Fragment key={i}>
                        <tr
                            key={row.id}
                            onClick={() => setSpecificPriceSetting(row.original)}
                            className={"hover:bg-gray-100 hover:cursor-pointer"}
                        >
                            {rowCells.map((cell) => (
                            <td key={cell.id} className="px-3 py-3 border text-sm">
                                {flexRender(cell.column.columnDef.cell, cell.getContext())}
                            </td>
                            ))}
                        </tr>
                        {row.getIsExpanded() && (
                            <></>
                            // <tr>
                            //   {/* 2nd row is a custom 1 cell row */}
                            //   <td colSpan={row.getVisibleCells().length}>
                            //     <LeadsTableSubComponent lead={row.original} />
                            //   </td>
                            // </tr>
                        )}
                        </Fragment>
                    );
                    })}
                </tbody>
        
                </table>
            </div>
        </>
    );
}