import { useState } from "react";

import { Label, Radio, RadioGroup, RadioGroupOption } from "@headlessui/react";

import Modal from "../../../layouts/Modal";
import { ICustomerGenericPriceSetting } from "../../../models/PriceSettings";

import SpecificPriceSingle from "./SpecificPriceSingle";

interface SpecificPriceModalProps {
    open: boolean,
    setOpen: React.Dispatch<React.SetStateAction<boolean>>,
    customerPriceConfigurationId: string,
    specificPriceSetting?: ICustomerGenericPriceSetting;
}

export default function SpecificPriceModal({ open, setOpen, specificPriceSetting, customerPriceConfigurationId }: SpecificPriceModalProps) {    


    return (
        <Modal width="max-w-6xl" open={open} setOpen={setOpen} >
            <h1 className="text-xl">{`${specificPriceSetting ? "Edit" : "Add"} Specific Price Setting`}</h1>
            
            <div className="mt-4">
                <div className="space-y-4 border-t border-gray-200" >
                    <div className="pt-4 grid grid-cols-1 gap-x-4 gap-y-4 sm:gap-y-6 sm:grid-cols-1">
                        {specificPriceSetting
                        ?
                            <SpecificPriceSingle 
                                customerPriceConfigurationId={customerPriceConfigurationId} 
                                specificPriceSetting={specificPriceSetting} 
                                onSuccess={() => setOpen(false)}
                            />
                        :
                            <SpecificPriceSingle 
                                customerPriceConfigurationId={customerPriceConfigurationId} 
                                onSuccess={() => setOpen(false)}
                            />
                        }
                        
                    </div>
                </div>
            </div>
        </Modal>
    )
}