import { useEffect, useState } from "react";
import usePostcodeGroupSingle from "../../data/usePostcodeGroupSingle";
import PostcodeAreasTable from "./PostcodeAreasTable";
import { CreatePostcodePriceRequest, IPostcodeGroup, IPostcodePrice } from './../../models/PostcodeGroup';
import usePostcodePriceMutations from "../../data/usePostcodePriceMutations";
import { TextInput } from "../shared/Inputs/TextInput";
import { useForm } from "react-hook-form";
import useDefaultCRUDHandlers from "../../hooks/useDefaultCRUDHandlers";
import { NumberInput } from "../shared/Inputs/NumberInput";
import { ButtonInput } from "../shared/Inputs/ButtonInput";
import AlphaNumericSort from "../../utils/alphaNumericSort";

interface FormValues {
  postcode: string;
  price: number;
}

export default function EditPostcodeAreas({ postcodeGroupId }: { postcodeGroupId: string }) {
  const { postcodeGroup } = usePostcodeGroupSingle(postcodeGroupId);
  const { create } = usePostcodePriceMutations();
  const { saveHandlers } = useDefaultCRUDHandlers("Postcode Area");
  const { register, handleSubmit, reset } = useForm<FormValues>();
  const [basePrice, setBasePrice] = useState<number>(0);
  const [postcodePrices, setPostcodePrices] = useState<IPostcodePrice[]>([]);

  const onSubmit = (data: FormValues) => {
    const newPostcodePrice: CreatePostcodePriceRequest = {
      ...data,
      postcodeGroupId: postcodeGroupId
    }

    create.mutate(newPostcodePrice, {
      onSuccess: () => {
        reset();
        saveHandlers.onSuccess();
      },
      onError: saveHandlers.onError
    });
  };

  useEffect(() => {
    if (!postcodeGroup.isLoading && postcodeGroup.data) {
      setPostcodePrices(postcodeGroup.data.data.postcodePrices);
      setBasePrice(postcodeGroup.data.data.basePrice);

      reset({
        price: postcodeGroup.data.data.basePrice
      });
    }
  }, [postcodeGroup.data, postcodeGroup.isLoading]);

  if (postcodeGroup.isLoading) return <></>;


  return (
    <div className="flex flex-col gap-y-4">
      <h1 className="text-xl">{"Edit Postcode Area Prices"}</h1>

      <form onSubmit={handleSubmit(onSubmit)} className="border-t border-gray-200">
        <div className="flex justify-normal items-center gap-x-4">
          <TextInput label={"Postcode"} register={register} registerName={"postcode"} />
          <NumberInput label={"Price"} register={register} registerName={"price"} minVal={null} />
          <ButtonInput label={"Add"} isSubmit={true} onClick={() => { }} classes={"mt-6"} />
        </div>
      </form>

      <div className="border-t border-gray-200">
        <PostcodeAreasTable postcodePrices={postcodePrices} outcode={postcodeGroup.data?.data.postcode!} />
      </div>
    </div>
  )


}