import { useEffect, useState } from "react";

import { useForm } from "react-hook-form";

import useNotification from "../../../components/notifications/useNotifications";
import usePriceSettingsMutations from "../../../data/usePriceSettingsMutations";
import useDefaultCRUDHandlers from "../../../hooks/useDefaultCRUDHandlers";
import { ICustomerGenericPriceSetting } from "../../../models/PriceSettings";
import { ButtonInput } from "../../shared/Inputs/ButtonInput";
import { NumberInput } from "../../shared/Inputs/NumberInput";
import { TextInput } from "../../shared/Inputs/TextInput";
import GeneralPriceSettingSingle from "../PriceConfiguration/GeneralPriceSettingSingle";


export default function SpecificPriceSingle({ customerPriceConfigurationId, specificPriceSetting, onSuccess }: { customerPriceConfigurationId: string, specificPriceSetting?: ICustomerGenericPriceSetting, onSuccess?: any }) {
  const { createSpecific, updateSpecific, removeSpecific } = usePriceSettingsMutations();
  const { addNotification } = useNotification();
  const { saveHandlers, deleteHandlers } = useDefaultCRUDHandlers("Price Settings");
  const [isLoading, setIsLoading] = useState(true);
  const { handleSubmit, register, setValue, watch, reset } = useForm<ICustomerGenericPriceSetting>({
    defaultValues: {
      id: specificPriceSetting?.id || "",
      customerPriceConfigurationId: customerPriceConfigurationId,
      make: specificPriceSetting?.make || null,
      model: specificPriceSetting?.model || null,
      fromYear: specificPriceSetting?.fromYear || null,
      toYear: specificPriceSetting?.toYear || null,
      fuelType: specificPriceSetting?.fuelType || null,
      engineCode: specificPriceSetting?.engineCode || null,
      fromEngineCc: specificPriceSetting?.fromEngineCc || null,
      toEngineCc: specificPriceSetting?.toEngineCc || null,

      priceSetting: {
        value: specificPriceSetting?.priceSetting?.value || 0,
        isFixed: specificPriceSetting?.priceSetting?.isFixed || true
      }
    }
  });

  // const enumArray = [
  //   ...Object.values(GenericPriceSettingType).filter((ct) => isNaN(Number(ct))),
  // ];

  // const options = enumArray.map((opt, i) => {
  //   return { id: i, name: opt };
  // });

  const sanitizeData = (data: any) => {
    data.priceSetting.value = parseFloat(data.priceSetting.value);
    data.priceSetting.isFixed = data.priceSetting.isFixed === "true" ? true : false;

    let valid = false;
    let keysToIgnore = ["id", "customerPriceConfigurationId", "priceSetting"];

    for (let key in data) {
      if (data[key] === "") {
        data[key] = null;
      }
      if (data[key] !== null && !keysToIgnore.includes(key)) {
        console.log(key, data[key]);
        valid = true;
      }
    }

    if (!valid) return null;

    if (data.fromYear !== null) data.fromYear = parseInt(data.fromYear);
    if (data.toYear !== null) data.toYear = parseInt(data.toYear);

    if (data.fromEngineCc !== null) data.fromEngineCc = parseInt(data.fromEngineCc);
    if (data.toEngineCc !== null) data.toEngineCc = parseInt(data.toEngineCc);

    return data;
  }

  const onSubmit = (data: any) => {
    let res = sanitizeData(data);
    if (res == null) {
      addNotification({
        variant: "error",
        primaryText: `Empty Form`,
      });
      return;
    } else data = res;
    if (specificPriceSetting) {
      updateSpecific.mutate(data, {
        ...saveHandlers,
        onSuccess: () => {
          saveHandlers.onSuccess();
          onSuccess();
        }
      });
      
    } else {
      data.id = null;
      createSpecific.mutate(data, {
        ...saveHandlers,
        onSuccess: () => {
          saveHandlers.onSuccess();
          reset();
          onSuccess();
        }
      });
    }
  };

  const onRemove = () => {
    if (specificPriceSetting) {
      removeSpecific.mutate(specificPriceSetting.id, {
        ...deleteHandlers,
        onSuccess: () => {
          deleteHandlers.onSuccess();
          onSuccess();
        }
      });
    }
  };

  useEffect(() => {
    if (specificPriceSetting && isLoading) {
      console.log("resetting", specificPriceSetting);
      reset(specificPriceSetting);
      setIsLoading(false);
    }
  }, [specificPriceSetting]);

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="space-y-4 pt-4">

      <div className="grid grid-cols-4 gap-x-4">

        {/* <SelectInput label="Type" register={register} registerName={`type`} options={options} setValue={setValue} watch={watch} cols="w-64" /> */}
        {/* <TextInput label={"Value"} registerName={`value`} register={register} placeholder="Make, Model, Year etc. " cols="w-64" /> */}

        <TextInput label={"Make"} registerName={`make`} register={register} />
        <TextInput label={"Model"} registerName={`model`} register={register} />

        <TextInput label={"Fuel Type"} registerName={`fuelType`} register={register} />
        <TextInput label={"Engine Code"} registerName={`engineCode`} register={register} />

        <div className="grid grid-cols-2 gap-x-4 mt-2">
          <NumberInput label={"From Year"} registerName={`fromYear`} register={register} minVal={0} />
          <NumberInput label={"To Year"} registerName={`toYear`} register={register} minVal={0} /> 
        </div>


        {/* divider */}
        {/* <div className="border-t-2 border-dashed border-primary-600 w-10 mt-7"></div> */}


        <div className="grid grid-cols-2 gap-x-4 mt-2">
          <NumberInput label={"From Engine CC"} registerName={`fromEngineCc`} register={register} minVal={0} />
          <NumberInput label={"To Engine CC"} registerName={`toEngineCc`} register={register} minVal={0} />
        </div>


        {/* divider */}
        {/* <div className="border-t-2 border-dashed border-primary-600 w-10 mt-7"></div> */}

        <GeneralPriceSettingSingle label={"Price Change"} objectName={"priceSetting"} register={register} setValue={setValue} watch={watch} minVal={null} />


        <div className="flex gap-x-4 mt-[36px] h-10">
          <ButtonInput label={specificPriceSetting ? "Save" : "Create"} isSubmit />
          {specificPriceSetting && <ButtonInput label="Delete" isSubmit={false} onClick={onRemove} classes="bg-red-600 hover:bg-red-600/80" />}
        </div>
      </div>


    </form>
  )

}