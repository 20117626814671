import { useEffect, useState } from "react";

import { useForm } from "react-hook-form";

import usePriceSettings from "../../../data/usePriceSettings";
import useDefaultCRUDHandlers from "../../../hooks/useDefaultCRUDHandlers";
import PriceRuleSingle from "./PriceRuleSingle";
import {
  CustomerPriceRuleType,
  ICustomerPriceRule,
} from "../../../models/PriceSettings";
import LoadingWheel from "../../shared/LoadingWheel";
import { PlusSmallIcon } from "@heroicons/react/20/solid";
import PriceRuleModal from "./PriceRuleModal";
import PriceRuleTable from "./PriceRuleTable";

export default function ExportRules() {
  const { priceSettings } = usePriceSettings();
  const [rules, setRules] = useState<ICustomerPriceRule[]>([]);
  const { saveHandlers } = useDefaultCRUDHandlers("Export Rule");
  const [isLoading, setIsLoading] = useState(true);
  const [modalOpen, setModalOpen] = useState(false);
  const [editRule, setEditRule] = useState<ICustomerPriceRule>();

  useEffect(() => {
    if (priceSettings.isLoading) return;
    if (!priceSettings.data?.data) return;

    let config = priceSettings.data?.data.customerPriceConfiguration;

    if (config?.customerPriceRules) {
      let exportRules = config.customerPriceRules.filter(
        (rule) => rule.type === CustomerPriceRuleType.Export
      );

      setRules(exportRules);
    }
    setIsLoading(false);
  }, [priceSettings.data, priceSettings.isLoading]);

  const handleSetPriceRule = (rule: ICustomerPriceRule) => {
    setEditRule(rule);
    setModalOpen(true);
  };

  const handleAddPriceRule = () => {
    setEditRule(undefined);
    setModalOpen(true);
  };

  if (isLoading) return <LoadingWheel />;

  return (
    <>
      <PriceRuleModal
        open={modalOpen}
        setOpen={setModalOpen}
        customerPriceConfigurationId={
          priceSettings.data?.data.customerPriceConfiguration?.id!
        }
        ruleName={"Export"}
        ruleType={CustomerPriceRuleType.Export}
        priceRule={editRule}
      />

      <div className="mt-4 flex justify-between">
        <button
          className=" bg-primary-600 hover:bg-primary-700 inline-flex items-center rounded-md border border-transparent px-3 py-2 text-sm font-medium leading-4 text-white shadow-sm"
          type="button"
          onClick={() => handleAddPriceRule()}
        >
          <PlusSmallIcon className="h-5 w-5 mr-2" />
          Add New Export Rule
        </button>
      </div>

      <div className="bg-white shadow rounded p-4">
        <h1 className="text-xl font-semibold">Export Rules</h1>
        <hr />

        <div className="mt-4 flex flex-col divide-y gap-y-6">
          {rules.length == 0 ? (
            <div className="text-center text-gray-400">No rules found</div>
          ) : (
            <>
              <PriceRuleTable 
                priceRules={rules} 
                setPriceRule={handleSetPriceRule} 
              />
            </>  
          )}

        </div>
      </div>
    </>
  );
}
